import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import Button from 'reactstrap/lib/Button';
// import { FormattedMessage } from 'react-intl';
import { withUser, withModalActions, withCoreComponent } from 'core/hocs';
import { FormattedTag, RecommendedGames as RecommendedGamesCore } from 'core/components';
import { LoggedIn } from 'components/sidebar/logged-in/logged-in';
import { LangSwitcher } from 'components/lang-switcher/lang-switcher';
import { USER_FIELDS, GAME_WINNER_LIST_PERIOD_TYPE } from 'core/constants';
// import { Logo } from 'components/logo/logo';
// import { IconArrowCircled } from 'components/icons/icon-arrow-circled/icon-arrow-circled';
// import { UserTabs } from 'components/user-tabs/user-tabs';
// import { IconMobileSearch } from 'components/icons/icon-mobile-search/icon-mobile-search';
import { Greeting } from 'components/sidebar/greeting/greeting';
import { SidebarMenu } from 'components/sidebar/sidebar-menu/sidebar-menu';
// import { GamesSearchPortal as GamesSearch } from 'components/games-search/games-search-portal';
import { GA } from 'helpers/ga';
import { YM } from 'helpers/ym';

import { MODAL_IDS } from '../../constants';

// import colors from '../../customizations/js/color-variables';

import './sidebar-mobile.scss';
import { IconClose } from '../icons/icon-close/icon-close';
import { SignInButtons } from '../header/sign-in-buttons/sign-in-buttons';
import { GameWinnerList } from '../game-winner-list/game-winner-list';
import { News } from '../news/news';

const {
  IS_USER_LOGGED_IN,
} = USER_FIELDS;

export class SidebarMobileUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    openModalAndCloseOthers: PropTypes.func.isRequired,
    closeSideMenu: PropTypes.func.isRequired,
    pageName: PropTypes.string,
    params: PropTypes.string,
    // games: PropTypes.arrayOf(PropTypes.shape()),
    signOut: PropTypes.func.isRequired,
    // openGame: PropTypes.func.isRequired,
  };

  static defaultProps = {
    pageName: null,
    params: null,
    // games: [],
  };

  state = {
    gameSearchIsOpen: false,
  };

  toggleGameSearch = () => this.setState(prevState => ({ gameSearchIsOpen: !prevState.gameSearchIsOpen }));

  openSignInModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.SIGN_IN);
  };

  openSignUpModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.SIGN_UP);

    GA.event({
      category: 'signup',
      action: 'click',
      label: 'SignFirst',
    });
    YM.event('SignFirst');
  };

  render() {
    const {
      params,
      closeSideMenu,
      isUserLoggedIn,
      signOut,
      pageName,
      // games: recommendedGames,
      // openGame,
    } = this.props;
    // const { gameSearchIsOpen } = this.state;
    // const isSportsbookPage = [PAGE_NAMES.SPORTSBOOK, PAGE_NAMES.IN_PLAY].includes(pageName);

    return (
      <div
        className={classNames('sidebar-mobile flex-column align-items-center pt-0_75 bg-blue-gradient position-relative px-2 w-100', {
          'pb-14': !isUserLoggedIn,
        })}
      >
        <div
          className="w-100 d-flex justify-content-between align-items-center"
        >

          <IconClose
            className="ml-n0_5 cursor-pointer"
            onClick={closeSideMenu}
          />

          {/* <Logo iconClassName="icon-logo" /> */}

          {/* <IconMobileSearch */}
          {/*   className="icon-search cursor-pointer" */}
          {/*   color={colors.gray40} */}
          {/*   onClick={this.toggleGameSearch} */}
          {/* /> */}
        </div>

        {!isUserLoggedIn ? (
          <div className="text-center m-auto">
            <Greeting className="align-items-center text-center pb-2_25" />
            <SignInButtons />
          </div>
        ) : (
          <div>
            <LoggedIn
              pageName={pageName}
              signOut={signOut}
              isWithinSidebar
            />
          </div>
          // <UserInfoAndLoyalty
          //   userBalance={userBalance}
          //   userInfoIsFetched={userInfoIsFetched}
          //   signOut={signOut}
          //   isWithinSidebar={isWithinSidebar}
          // />
        )}

        <SidebarMenu pageName={pageName} params={params} />

        <div className="d-flex flex-column mb-4">
          <LangSwitcher
            className="lang-switcher-sidebar lang-switcher-sidebar-modal w-100"
            withText
            isWithinSidebar
          />
        </div>
        <div className="w-100">
          <FormattedTag id="news-games.title" className="news-title h3 d-block text-uppercase" />

          <News
            // openGame={openGame}
            coreProps={{ size: 3 }}
            closeSidebar={closeSideMenu}
          />

          <FormattedTag id="latest-big-wins.title" className="winners-title h3 d-block mt-2_5 text-uppercase" />

          <GameWinnerList
            coreProps={{
              size: 6,
              periodType: GAME_WINNER_LIST_PERIOD_TYPE.DAILY,
              period: 1,
            }}
            isWithinSidebar
            closeSidebar={closeSideMenu}
          />
        </div>
        {/* <UserTabs */}
        {/*  className={classNames('px-1_5', { */}
        {/*    'mt-1 pr-2_25': isUserLoggedIn, */}
        {/*  })} */}
        {/*  isUserLoggedIn={isUserLoggedIn} */}
        {/*  isInSidebar */}
        {/*  coreProps={{ size: 99 }} */}
        {/* /> */}

        {/* {!isUserLoggedIn && ( */}
        {/*  <div className="auth-buttons w-100 position-fixed px-2 pt-1_25 bg-light"> */}
        {/*    <Button */}
        {/*      color="primary" */}
        {/*      onClick={this.openSignUpModal} */}
        {/*      className="no-effects" */}
        {/*      block */}
        {/*    > */}
        {/*      <FormattedMessage id="sign-up" /> */}
        {/*    </Button> */}

        {/*    <Button */}
        {/*      outline */}
        {/*      color="white-20" */}
        {/*      onClick={this.openSignInModal} */}
        {/*      className="no-effects mt-1_25 mb-2 btn-outline-gray-5" */}
        {/*      block */}
        {/*    > */}
        {/*      <FormattedMessage id="login" /> */}
        {/*    </Button> */}
        {/*  </div> */}
        {/* )} */}

        {/* {gameSearchIsOpen && ( */}
        {/*  <GamesSearch */}
        {/*    recommendedGames={recommendedGames} */}
        {/*    onClose={this.toggleGameSearch} */}
        {/*    isModal */}
        {/*    className="d-md-none" */}
        {/*  /> */}
        {/* )} */}
      </div>
    );
  }
}

export const SidebarMobile = withCoreComponent(
  RecommendedGamesCore,
  withModalActions(
    withUser(SidebarMobileUI, [IS_USER_LOGGED_IN]),
  ),
);
